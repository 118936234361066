<template>
  <div :class="isUsed ? 'voucher-used mb-3' :'voucher mb-3'">
    <v-layout wrap @click="open()">
      <v-flex xs10>
        <div :class="isUsed ? 'text-used pull-left pt-2 pl-2' :' text pull-left pt-2 pl-2'">
          <v-icon medium :color="isUsed ? '#008000' : '#ffae19'"> mdi-ticket-percent </v-icon>
          <span class="ml-2"> {{ title }} </span>
        </div>
      </v-flex>
      <v-flex xs2>
        <v-btn icon :color="isUsed ? '#008000' : '#ffae19'" class="pull-right" @click="open()">
          <v-icon medium>mdi-chevron-right</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog"
      :width="isMobile ? '300px' : '400px'"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="false"
    >
      <div class="voucher-pop" v-if="dialog">
        <v-layout wrap> 
          <v-flex xs12 class="mt-5">
            <v-text-field
              :color="isUsed ? '#008000' : '#ffae19'"
              tile
              class="mb-0"
              outlined
              v-model="voucher_code"
              placeholder="Voucher Code"
              ref="voucher_code"
              append-icon="mdi-magnify"
              @click:append="search()"
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 :class="isUsed ? 'voucher-coupon-used' : 'voucher-coupon'" v-if="voucher_result">
            <v-layout wrap>
              <v-flex xs3 :class="isUsed ? 'voucher-coupon-used-1' : 'voucher-coupon-1' ">
                <v-icon x-large color="#fff" class="mt-3"> mdi-ticket-percent </v-icon>
              </v-flex>
              <v-flex xs9 class="voucher-coupon-2">
                <div>
                  <p> {{ voucher_result.type }} - {{ voucher_result.name }}</p>
                  <p v-html="voucher_result.description"></p>
                  <p v-if="delivery_price"> {{ addComma(voucher_result.total_promo) }} IDR </p>
                  <v-btn outlined tile :color="isUsed ? '#008000' : '#ffae19'" class="pull-right" @click="action()">
                    <span v-if="isUsed">REMOVE</span>
                    <span v-if="!isUsed">USE NOW</span>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 v-else class="center">
            <span>No Data</span>
          </v-flex>
          <v-flex xs12 class="center mt-16">
            <v-btn tile outlined color="#a0a0a0" @click="close()">
              CLOSE
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import crypto from '@/crypto'

  export default {
    name: 'Voucher',
    props : {
      total : {
        type: Number,
        default: 0
      },
      delivery_price : {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        title: 'Use Voucher',
        dialog: false,
        voucher_code: '',
        voucher_result: null,
        isUsed: false
      }
    },
    watch: {
      dialog (val) {
        if (val) {
          if (this.voucher) {
            this.voucher_result = this.voucher
          }
        } else {
          this.voucher_result = null
          this.voucher_code = ''
        }
      },
      voucher (val) {
        if (val) {
          this.title = val.name
          this.isUsed = true
        } else {
          this.title = 'Use Voucher'
          this.isUsed = false
        }
      }
    },
    computed: {
      ...mapState([
        'cart',
        'isMobile',
        'token',
        'voucher'
      ])
    },
    methods: {
      open () {
        this.dialog = true
      },
      close () {
        this.dialog = false
      },
      action () {
        if (this.isUsed) {
          this.remove()
        } else {
          this.use()
        }
      },
      use () {
        localStorage.setItem('prepp_v', crypto.encrypt(this.voucher_result))
        this.$store.commit('setVoucher', this.voucher_result)
        this.$store.commit('setSnackbar', true)
        this.$store.commit('setText', 'Success use voucher')
        this.isUsed = true
      },
      remove () {
        localStorage.removeItem('prepp_v')
        this.$store.commit('setVoucher', null)
        this.$store.commit('setSnackbar', true)
        this.$store.commit('setText', 'Success remove voucher')
        this.isUsed = false
      },
      search () {
        this.$store.commit('setIsLoading', true)

        let body = {
          kode_promo: this.voucher_code,
          total_transaction: this.total,
          delivery_price: (this.delivery_price && this.delivery_price > 0) ? this.delivery_price : 10000,
          products:  this.cart.map(item => {
            return {
              sku_product: item.size.sku_product,
              qty: item.quantity
            }
          })
        }

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token,
          is_offline: true
        }

        axios
          .post('/api/v1/orders/promo', body)
          .then(response => {
            this.voucher_result = response.data.data
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      setSavedvoucher () {
        let data = localStorage.getItem('prepp_v')
        if (data) {
          this.$store.commit('setVoucher', crypto.decrypt(data))
        }
      }
    },
    mounted () {
     this.setSavedvoucher()
    }
  }
</script>

<style scoped>
  .voucher {
    /* max-width: 300px; */
    cursor: pointer;
    border: 2px dashed #ffae19;
    padding: 3px;
    text-align: center;
    background-color: #ffedcc;
  }

  .voucher-used {
    /* max-width: 300px; */
    cursor: pointer;
    border: 2px dashed #008000;
    padding: 3px;
    text-align: center;
    background-color: #b1ffb1;
  }


  .voucher-pop {
    background-color: #fff;
    padding: 10px;
    min-height: 300px;
  }

  .voucher-coupon {
    border: 1px solid #ffae19;
    min-height: 75px;
  }

  .voucher-coupon-1 {
    background-color: #ffae19;
    text-align: center;
    padding: 5px;
    min-height: 75px;
  }

  .voucher-coupon-2 {
    background-color: #fff;
    padding: 5px;
    min-height: 75px;
  }

  .voucher-coupon-used {
    border: 1px solid #008000;
    min-height: 75px;
  }

  .voucher-coupon-used-1 {
    background-color: #008000;
    text-align: center;
    padding: 5px;
    min-height: 75px;
  }

  p{
    color: #121212;
    font-weight: 500;
    font-size: 0.8rem;
    margin: 0;
    padding: 0 0 0 5px;
  }

  .center {
    text-align: center;
    color: #c0c0c0;
    font-size: 0.8rem;
  }

  .text {
    display: inline-block;
    font-size: 0.8rem;
    color: #ffae19;
  }

  .text-used {
    display: inline-block;
    font-size: 0.8rem;
    color: #008000;
  }

  .pull-right {
    float: right;
  }

  .pull-left {
    float: left;
  }
</style>
